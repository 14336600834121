
<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
        <h5>Access levels</h5>
    </div>
    <div class="card-body">
     
    </div>
  </div>
</template>

<script>

import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
export default {

  mounted() {
       this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Settings", route: "/settings" },
      { title: "Roles and Permissions" },
    ]);
  },
};
</script>

